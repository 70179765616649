/* src/components/Footer.css */
@import '../index.css';
@import 'bootstrap/dist/css/bootstrap.css';


/* HeaderNav*/

.headernav__scroll {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: rgba(18, 129, 64, 0.65);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__logo img {
    width: 60px;
    height: 60px;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__logo span {
    color: azure;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__text__logo1 {
    font-size: 18px;
    letter-spacing: 4px;
    font-weight: 600;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__text__logo2 {
    font-size: 9px;
    font-weight: 500;
    letter-spacing: .5px;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__container {
    margin: 0 4% 0 4%;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__link a {
    text-decoration: none;
    font-weight: 600;
    color: azure;
    position: relative;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__link a:hover {
    padding-top: 2px;
}

.headernav__link a::after {
    content: "";
    display: block;
    height: 2px;
    background-color: azure;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.headernav__link a:hover::after {
    opacity: 1;
}

/* HeaderNav Scroll*/

.headernav__noscroll {
    height: max-content;
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 0;
    background-color: rgba(18, 129, 64, 0.65);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__logoscroll,
.headernav__logo {
    text-decoration: none;
}


.headernav__logoscroll img {
    width: 50px;
    height: 50px;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__logoscroll span {
    color: white;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__text__logo1scroll {
    font-size: 16px;
    letter-spacing: 4px;
    font-weight: 600;
}

.headernav__text__logo2scroll {
    font-size: 8px;
    letter-spacing: .5px;

}

.headernav__linkscroll a {
    text-decoration: none;
    font-weight: 600;
    color: white;
    position: relative;
}

.headernav__linkscroll a::after {
    content: "";
    display: block;
    height: 2px;
    background-color: white;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.headernav__linkscroll a:hover::after {
    opacity: 1;
}

.headernav__text__af {
    background-color: var(--main-color-yell);
    text-decoration: none;
    color: white;
    padding: 8px 30px 8px 30px;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    animation: pulse 2s infinite;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Menú Hamburguesa */

.menu-hamburguesa,
.menu-hamburguesa-2 {
    display: none;
}

.menu-hamburguesa-container {
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.menu-hamburguesa span {
    width: 100%;
    height: 4px;
    background-color: white;
    /* color de las barras */
    display: block;
    border-radius: 50px;
}

.menu-hamburguesa-2 span {
    width: 100%;
    height: 4px;
    background-color: white;
    /* color de las barras */
    display: block;
    border-radius: 50px;
}

.menu-hamburguesa-container:hover {
    background-color: rgba(196, 196, 196, 0.426);
    padding: 1px;
    /* color de las barras */
}

.menu-hamburguesa span:nth-child(2),
.menu-hamburguesa-2 span:nth-child(2) {
    margin: 0.5em 0;
}


/* DropDown responsive*/

.headernav__dropdown {
    margin-top: 5px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(18, 129, 64, 0.85);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__dropdown a {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

.headernav__dropdown__scroll {
    margin-top: 5px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.headernav__dropdown__scroll a {
    text-decoration: none;
    color: rgba(18, 129, 64, 0.85);
    font-size: 14px;
}


@keyframes pulse {
    0% {
        transform: scale(1);
        background-color: var(--main-color-yell);
    }

    50% {
        transform: scale(1.1);
        background-color: var(--main-color-red);
    }

    100% {
        transform: scale(1);
        background-color: var(--main-color-yell);
    }
}


/* Responsive */

@media only screen and (max-width: 1100px) {

    .headernav__container {
        margin: 0 1% 0 1%;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__af {
        color: white;
        padding: 6px 22px 6px 22px;
        font-size: 12px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__link a {
        font-size: 14px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media only screen and (max-width: 900px) {
    .headernav__links_link {
        display: none;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__container {
        margin: 0 4% 0 4%;
    }

    .headernav__links {
        justify-content: end;
    }

    .menu-hamburguesa,
    .menu-hamburguesa-2 {
        display: block;
        width: 20%;
        display: flex;
        margin: 2% 2% 2% 2%;
        align-items: center;
        justify-content: flex-end;
    }

    .menu-hamburguesa-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .headernav__text__container {
        width: 100%;
    }


    .headernav__text__af {
        width: 40%;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin-left: 15px;
        padding: 6px 25px 6px 25px;
    }

    /* Logo */

    /* Logo */
    .headernav__logoscroll img {
        width: 50px;
        height: 50px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__logo img {
        width: 50px;
        height: 50px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo1scroll {
        font-size: 14px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo1 {
        font-size: 14px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo2scroll {
        font-size: 7.6px;
        letter-spacing: .3px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo2 {
        font-size: 7.6px;
        letter-spacing: .3px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }


}

@media only screen and (max-width: 680px) {

    /* Header */

    .headernav__scroll {
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    /* Logo */
    .headernav__logoscroll img {
        width: 40px;
        height: 40px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__logo img {
        width: 40px;
        height: 40px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo1scroll {
        font-size: 12px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo1 {
        font-size: 12px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo2scroll {
        font-size: 6.8px;
        letter-spacing: .3px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo2 {
        font-size: 6.8px;
        letter-spacing: .3px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media only screen and (max-width: 520px) {

    .headernav__text__af {
        padding: 4px 16px 4px 16px;
        font-size: 10px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo1scroll {
        font-size: 10px;
        letter-spacing: 2.5px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo1 {
        font-size: 10px;
        letter-spacing: 2.5px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo2scroll {
        font-size: 5.5px;
        letter-spacing: .1px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .headernav__text__logo2 {
        font-size: 5.5px;
        letter-spacing: .1px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}