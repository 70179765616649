/* src/components/Footer.css */
@import '../index.css';
@import 'bootstrap/dist/css/bootstrap.css';

.marque-wrapper img {
    box-sizing: inherit;
    vertical-align: baseline;
    padding-inline: 5px;
    width: 50px;
    height: 50px;
}

.card__wripper {
    background-color: #ababab3a;
    box-shadow: 0 0 10px #0000001a;
    padding: 5px;
}

.card__wripper_maq {
    gap: 20px;
    margin-right: 20px;
}

.marquee__div {
    gap: 8px;
    width: 150px;
    height: 150px;
    padding: 30px;
    border-radius: 50%;
    border: 7px solid white;
}

.marquee__div span {
    font-size: 10px;
    color: white;
    text-align: center;
}

.blue {
    background-color: var(--main-color-blue);
}

.red {
    background-color: var(--main-color-red);
}

.yellow {
    background-color: var(--main-color-yell);
}

.green {
    background-color: var(--main-color-logo);
}