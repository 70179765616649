/* src/components/Footer.css */
@import '../index.css';
@import 'bootstrap/dist/css/bootstrap.css';

.main_container {
    margin-top: 30px;
    margin-bottom: 30px;
}

.image__container img {
    width: 400px;
    border-radius: 52% 48% 72% 28% / 39% 30% 70% 61%;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide__info h2 {
    color: var(--main-color-yell);
    font-size: 36px;
    margin-bottom: 5%;
    font-weight: 600;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide__info p {
    color: var(--text-color-1);
    font-size: 18px;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: center;
}

.image__containerb img {
    width: 400px;
    border-radius: 13% 96% 57% 51% / 51% 46% 40% 47%;
    text-align: center;
}

.slide__infob h2 {
    color: var(--main-color-blue);
    font-size: 36px;
    margin-bottom: 5%;
    font-weight: 600;
    text-align: center;
}

.slide__infob p {
    color: var(--text-color-1);
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}


/* Responsive */

@media only screen and (max-width: 1100px) {

    .image__container img,
    .image__containerb img {
        width: 300px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info h2,
    .slide__infob h2 {
        font-size: 32px;
        font-weight: 600;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info p,
    .slide__infob p {
        font-size: 16px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media only screen and (max-width: 900px) {

    .image__container img,
    .image__containerb img {
        width: 250px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info h2,
    .slide__infob h2 {
        font-size: 28px;
        font-weight: 600;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info p,
    .slide__infob p {
        font-size: 14px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media only screen and (max-width: 680px) {

    .image__container img,
    .image__containerb img {
        width: 200px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info h2,
    .slide__infob h2 {
        font-size: 26px;
        font-weight: 600;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info p,
    .slide__infob p {
        font-size: 12px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media only screen and (max-width: 520px) {

    .image__container img,
    .image__containerb img {
        width: 160px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info h2,
    .slide__infob h2 {
        font-size: 22px;
        font-weight: 600;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .slide__info p,
    .slide__infob p {
        font-size: 11px;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}