h4,
.h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.2;

}


h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
  text-align: center;
  font-family: 'Times New Roman', Times, serif;

}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 2rem;
  }
}



h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h4,
  .h4 {
    font-size: 1.5rem;
  }
}


p {
  margin-top: 0;
  margin-bottom: 1rem;
}



a {
  color: #128140;
  text-decoration: underline;
}

a:hover {
  color: #76bb9b;
}

.page-section {
  margin-top: -20px;
}

.page-section .breadcrumbs {
  text-align: center;
  background: #128140;
  padding: 10px 0;
  color: #fff;
}


.conter1 {
  padding-top: 2rem;
  align-content: center;
}

.n {
  margin-top: 47px;
  text-align: center;
  background: #128140;
  padding: 0px 0;
  color: #fff;
}

.container2 {
  padding-top: 100px;
}


/*--------------------------------------------------------------
# Nosostros
--------------------------------------------------------------*/


.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}


.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}

.timeline>li:after,
.timeline>li:before {
  display: table;
  content: " ";
}

.timeline>li:after {
  clear: both;
}

.timeline>li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 3px solid #128140;
  border-radius: 100%;
  background-color: #128140;
}

.timeline>li .timeline-image h4,
.timeline>li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4,
.timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading,
.timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}

.timeline .timeline-body>ul,
.timeline .timeline-body>p {
  margin-bottom: 0;
}

@media (min-width: 300px) {
  .timeline:before {
    left: 0%;
  }

  .container2 p{
    padding: 0px 90px 0px 0rem;
  }

  .container2 h2{
    padding: 0px 90px 0px 10px;
  }

  .timeline>li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .page-section {
    margin-top: -20px;
    display: flex;
  }
  
  
  .conter1 {
    padding-top: 2rem;
    align-content: center;
  }

  .timeline>li .timeline-panel {
    float: right;
    width: 90%;
    padding: 120px 50px 20px 0rem;
    text-align: justify;
  }

  .timeline>li .timeline-image {
    left: 0%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  

  .timeline>li.timeline-inverted>.timeline-panel {
  
    float: right;
    width: 90%;
    padding: 120px 50px 20px 0rem;
    text-align: justify;
   
  }
}

@media (min-width: 600px) {
  .timeline:before {
    left: 50%;
  }

  .timeline>li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .timeline>li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 20px;
    text-align: justify;
  }

  .timeline>li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .container2 p{
    padding: 0px 0px 0px 0rem;
  }
  
  .container2 h2{
    padding: 0px 0px 0px 0rem;
  }

  .timeline>li .timeline-image h4,
  .timeline>li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    width: 41%;
    padding: 0 20px 20px 20px;
    text-align: justify;
  }
}

@media (min-width: 992px) {
  .timeline>li {
    min-height: 150px;
  }

  .container2 p{
    padding: 0px 0px 0px 0rem;
  }
  
  .container2 h2{
    padding: 0px 0px 0px 0rem;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline>li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline>li .timeline-image h4,
  .timeline>li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  
  .timeline>li {
    min-height: 170px;
  }

  .container2 p{
    padding: 0px 0px 0px 0rem;
  }
  
  .container2 h2{
    padding: 0px 0px 0px 0rem;
  }

  .conter2 .fila{
    align-content: center;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline>li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline>li .timeline-image h4,
  .timeline>li .timeline-image .h4 {
    margin-top: 40px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 20px 20px 15px;
  }
}

.team-member {
  margin-bottom: 3rem;
  text-align: center;
}

.team-member img {
  width: 14rem;
  height: 14rem;
  border: 0.5rem solid rgba(0, 0, 0, 0.1);
}

.team-member h4,
.team-member .h4 {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

/*-----------------------------------------
# Aliados
-------------------------------------------*/
.img-brand {
  height: 5rem;
  width: auto;
}

.img-brand1 {
  height: 3rem;
  width: auto;
}

.img-brand2 {
  height: 60px;
  width: auto;
}

.img-brand3 {
  height: auto;
  width: auto;
}