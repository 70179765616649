/* src/components/Footer.css */
@import '../index.css';
@import 'bootstrap/dist/css/bootstrap.css';


.wrap__section {
    margin-top: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.wrap__section__comp {
    margin-top: 10px;
    margin-bottom: 10px;
}


.wrap__icon {
    margin-left: 10px;
    width: 210px;
}

.wrap__icon h4 {
    color: var(--text-color-1);
    font-weight: 600;
    margin: 0;
    font-size: 18px;
}

.wrap__icon p {
    color: var(--text-color-1);
    font-weight: 500;
    margin: 0;
}

.slideImg {
    width: 50px;
    height: 50px;
}

@media only screen and (max-width: 1100px) {}

@media only screen and (max-width: 900px) {
    .wrap__icon h4 {
        font-size: 16px;
    }

    .wrap__icon p {
        font-size: 14px;
    }

    .wrap__icon {
        width: 200px;
    }
}

@media only screen and (max-width: 680px) {

    .wrap__icon h4 {
        font-size: 14px;
    }

    .wrap__icon p {
        font-size: 12px;
    }


    .wrap__icon {
        width: 190px;
    }
}

@media only screen and (max-width: 520px) {

    .wrap__icon {
        width: 180px;
    }
}