* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

:root {

  /*Backgrunds*/
  --bg-FooterCopyright: #1a1919;

  /*Colors*/
  --main-color-logo: #128140;
  --slider-carrousel: #40cc7a;
  --main-color-yell: #ffc107;
  --main-color-red: #ff6347;
  --main-color-blue: #007bff;
  --footer-color-text-1: #40cc7a;
  --footer-color-text-2: #128140;

  --text-color-1: #222222;
}


/* Config responsive */

@media only screen and (max-width: 1100px) {}

@media only screen and (max-width: 900px) {}

@media only screen and (max-width: 680px) {}

@media only screen and (max-width: 520px) {}

/* Transición global 
transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
*/