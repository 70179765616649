/* src/components/Footer.css */
@import '../index.css';
@import 'bootstrap/dist/css/bootstrap.css';

/* BackGround Principal*/

.bg__main {
    background-image: url('../../public/img/BackgroundMain.avif');
    background-size: cover;
    background-position: center;
}

/* Home*/
.bodyHomePage {
    background-color: transparent;
}

.caption {
    position: absolute;
}

.caption p {
    font-size: 60px;
    font-weight: 700;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.caption p:hover {
    color: var(--main-color-yell);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.caption a {
    text-decoration: none;
}

.HomePage__sliders {
    margin-top: 0;
}

.HomePage__sliders .caption {
    bottom: 40%;
    left: 0;
    right: 0;
    text-align: center;
}


.HomePage__sliders {
    width: 100%;
    height: 600px;
    overflow: hidden;
    position: relative;
    padding-top: 105px;
}

.HomePage__sliders img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
}

.HomePage__sliders .dot {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    gap: 30px;
}


.HomePage__sliders .dot li {
    list-style-type: none;
    display: inline-block;
    margin: 0 30px;
}

.HomePage__sliders .dot button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    opacity: 0.4;
    border-color: transparent;
}


.HomePage__sliders .dot .active {
    opacity: 1;
    background-color: var(--slider-carrousel);
}

.HomePage__sliders .dark {
    filter: brightness(90%);
}

.HomePage__sliders {
    color: black;
}

.HomePage__sliders .light {
    filter: brightness(90%);
}

.HomePage__sliders .light {
    color: white;
}



.text-dark p {
    color: azure;
}

.text-light p {
    color: azure;
}

/* Responsive */

@media only screen and (max-width: 1100px) {}

@media only screen and (max-width: 900px) {
    .caption p {
        font-size: 50px;
        font-weight: 700;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media only screen and (max-width: 680px) {
    .caption p {
        font-size: 40px;
        font-weight: 600;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

@media only screen and (max-width: 520px) {
    .caption p {
        font-size: 30px;
        font-weight: 600;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
}