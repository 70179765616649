@import '../index.css';
@import 'bootstrap/dist/css/bootstrap.css';

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 0px 0px 0px 0px;
  overflow: hidden;
  display: flex;
}

.container__main__contact {
  background-image: url('../../public/img/serafima-lazarenko-QesdKhurvKM-unsplash.jpg');
  background-size: cover;
  background-position: center;
}

.breadcrumbs {
  padding: 10px;
}

.container-2 {
  margin-top: 100px;
  text-align: center;
  color: #fff;
}

.container {
  padding-top: 0px;
  margin: 10px 50px;
}

.breadcrumbs h2 {
  font-size: 38px;
  font-weight: 500;
}

.breadcrumbs p {
  font-size: 14px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  padding-top: 5px;
}

.contact .info {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
}

.contact .info i {
  font-size: 20px;
  color: #128140;
  float: left;
  width: 44px;
  height: 44px;
  background: #ecf9f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: azure;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: azure;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #128140;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}


.contact .php-email-form input {
  height: 44px;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.6);
}

.contact .php-email-form input::placeholder {
  color: azure;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.6);
}

.contact .php-email-form textarea::placeholder {
  color: azure;
}

.contact .php-email-form button[type=submit] {
  background: #128140;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #0dd35f;
}

.maps {
  margin-top: 10px;
  border-radius: 15px;
}

.form-check-label {
  color: azure;
}

.form-check-label a {
  color: azure;
  font-weight: 600;
}